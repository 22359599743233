<template>
  <div>
    <select-shop id="stockOutSelectShop" :loadingButton="loadingButton" v-on:getData="getStockOut"></select-shop>
    <div v-if="isPermission">
      <CCard>
        <CCardBody>
          <div class="row">
            <div class="col-md-9 col-sm-9 col-8 col-xl-10 d-inline-flex align-items-start">
              <div>
                <div class="d-flex align-items-center">
                  <h2 class="mb-0">{{ $t('management.stockOutDoc') }}</h2>
                  <HelpButton id="stockOutHelpButton" :isHeader="false" class="ml-2"></HelpButton>
                </div>
                <p class="mt-2">
                  {{ $t("moreInfo") }}
                  <i class="fi fi-rr-info ml-1" @click="openHelpModal = true"></i>
                </p>
                <show-date></show-date>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-4 col-xl-2 text-right">
              <CButton id="stockOutCreateButton" v-if="isEditData" to="/inventory/stock-out/create"  block color="warning" style="display: flex; align-items: center; justify-content: center; color: white;">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i> {{ $t('transactionType4') }}
              </CButton>
              <CButton id="stockOutExportButton" v-on:click="exportReport()" v-if="isExport" style="display: flex; align-items: center; justify-content: center;" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <search-stock id="stockOutSearchStock" :searchAttr="searchAttr" :keyword="keyword" v-on:getSearch="setSearch">
          </search-stock>
          <CRow>
            <CCol sm="12" lg="12">
              <hr />
              <div style="overflow-x: auto" class="text-dark">
                <CDataTable id="stockOutDataTable" :items="updateItems()" :fields="fields" :loading="loading" hover border clickableRows
                  @row-clicked="stockDocumentDetail" style="white-space: nowrap; color: dark;" :row-class="(item) => item._classes" >
                  <template id="stockOutNoItemsView" #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                  <template #stockNo="{ item, index }">
                    <tr :id="'stockOutRow' + index">
                      <td>{{ item.stockNo }}</td>
                    </tr>
                  </template>
                </CDataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getStockOut">
          </pagination>
        </CCardBody>
      </CCard>
      <CModal
        :show.sync="openHelpModal"
        centered
        :title="$t('userManual')"
        color="success"
        header="false"
        size="lg"
      >
        <div class="col-12 p-4" >
          <h4 class="text-center">{{ $t("stockOutGuideTitle") }}</h4>
          <p class="h5">
            <div class="d-flex justify-content-center">
              <!-- <img src="/img/helpTransferIn.jpg" class="img-fluid p-2 mb-2" /> -->
            </div>            
              <div class="" style="font-weight:1000; font-size: 18px;">{{ $t('stockOutMethodsIntro') }} :</div>
              <div  style="font-weight:1000; font-size: 18px;">{{ $t('stockOutStepsTitle') }} </div>
              <ol style="font-size: 16px;">
                <li>{{ $t('stockOutStep1') }}</li>
                <li>{{ $t('stockOutStep2') }}</li>
                <li>{{ $t('stockOutStep3') }}</li>
                <li>{{ $t('stockOutStep4') }}</li>
              </ol>        
            </p>
        </div>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4" >
              <CButton @click="openHelpModal = false" color="outline-success"  block>
                <b>{{ $t("submit") }}</b>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/local'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import report from '@/services/report'
import exportStock from '@/print/export'
import permis from '@/util/permission'
import SearchStock from '@/containers/SearchStock'
import HelpButton from '../../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    SearchStock,
    HelpButton
  },
  data() {
    return {
      data: [],
      dataExport: [],
      loadingButton: true,
      openHelpModal: false,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      keyword: '',
      searchAttr: '',
    }
  },
  computed: {
    ...mapGetters(['users', 'date', 'shops', 'access', 'permissions']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('inventory', this.$route.path)
    },
    isDeleteData() {
      return permis.findPermissionRemove('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    permission() {
      let permission = false
      if (this.permissions.inventory == undefined) {
        permission = false
      } else {
        if (this.permissions.inventory.stockOut == undefined) {
          permission = false
        } else {
          permission = this.permissions.inventory.stockOut
        }
      }
      return permission
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'createdAt',
          label: this.$i18n.t('createdAt'),
          _classes: 'font-weight-normal  text-left',
        },
        {
          key: 'updatedAt',
          label: this.$i18n.t('lastUpdate'),
          _classes: 'font-weight-normal text-left'
        },
        { key: 'date', label: this.$i18n.t('dateOfBill'), _classes: 'font-weight-normal text-left' },
        { key: 'stockNo', label: this.$i18n.t('documentNo'), _classes: 'font-weight-normal text-left' },
        { key: 'billNo', label: this.$i18n.t('billNo'), _classes: 'font-weight-normal text-left' },
        {
          key: 'grandTotal',
          label: this.$i18n.t('docTotal'),
          _classes: 'text-right font-weight-normal ',
        },
        {
          key: 'customer',
          label: this.$i18n.t('customer'),
          _classes: ' font-weight-normal text-left',
        },
        {
          key: 'username',
          label: this.$i18n.t('username'),
          _classes: 'font-weight-normal text-left',
        }, {
          key: 'status',
          label: this.$i18n.t('status'),
          _classes: 'font-weight-normal text-left',
          class: 'status-header'
        },
      ]
    },
    items() {
      let data = this.data || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const customer = item.customer
        const customerName = ''

        if (customer === null || customer.name === null || customer.name.trim() === "") {
            customerName = '-';
        } else {
            customerName = customer.name;
        }
        const grandTotal = util.convertCurrency(item.grandTotal || 0)
        const billNo = item.billNo || '-'
        const stockNo = item.stockNo || '-'

        // Parse deleted at document.
        let classes = 'text-dark'
        let status = this.$i18n.t('active')
        if (item.deleted_at != null) {
          status = this.$i18n.$t('cancel')
          classes = 'text-danger'
        }

        // Updated at
        let updatedAt = ''
        if (item.updated_at != undefined) {
          updatedAt = moment(String(item.updated_at)).format('DD/MM/YYYY HH:mm:ss')
        }

        const updatedBy = item.updated_by || {}
        const updatedByName = updatedBy.username || '-'

        // date of bill 
        let date = ''
        if (item.date != undefined) {
          date = moment(String(item.date)).format('DD/MM/YYYY')
        }

        // Created at
        let createdAt = ''
        if (item.created_at != undefined) {
          createdAt = moment(String(item.created_at)).format('DD/MM/YYYY HH:mm:ss')
        }
        detail.push({
          objectId: item.objectId,
          createdAt: createdAt,
          date: date,
          stockNo: stockNo,
          updatedAt: updatedAt,
          billNo: billNo,
          grandTotal: grandTotal,
          username: updatedByName,
          customer: customerName,
          _classes: classes,
          status: status,
        })
      }
      return detail
    },
    // itemsExport() {
    //   let data = this.dataExport
    //   let detail = []
    //   let customer = ''
    //   let classes = ''
    //   let status = ''

    //   if (data != undefined) {
    //     for (let i = 0; i < data.length; i++) {
    //       if (data[i].customer != undefined) {
    //         customer = data[i].customer.name
    //       } else {
    //         customer = '-'
    //       }
    //       if (data[i].deleted_at != null) {
    //         classes = 'text-danger'
    //         status = 'ยกเลิก'
    //       } else {
    //         classes = 'text-black'
    //         status = 'ใช้งาน'
    //       }

    //       let updated_by = ''
    //       if (data[i].updated_by !== undefined) {
    //         if (data[i].updated_by.username !== undefined) {
    //           updated_by = data[i].updated_by.username
    //         }
    //       }

    //       detail.push({
    //         objectId: data[i].objectId,
    //         date: moment(String(data[i].created_at)).format(
    //           'DD MMMM YYYY HH:mm:ss'
    //         ),
    //         stockNo: data[i].stockNo,
    //         billNo: data[i].billNo,
    //         grandTotal: util.convertCurrency(data[i].grandTotal),
    //         username: updated_by,
    //         customer: customer,
    //         _classes: classes,
    //         status: status,
    //       })
    //     }
    //   } else {
    //     detail = []
    //   }
    //   return detail
    // },
    setExportdata() {
      let data = this.dataExport
      let counttotal = 0
      let conntCancelItems = 0
      let countCancelTotal = 0
      let countstockIntotal = 0
      let count = 0
      let totalItems = 0
      let detail = []
      let shops = this.shops.find((i) => i.objectId === this.shopObjectId)
      for (let i = 0; i < data.length; i++) {
        counttotal = counttotal + data[i].grandTotal
        totalItems = totalItems + i
        if (data[i].deleted_at != null) {
          conntCancelItems = conntCancelItems + 1
          countCancelTotal = countCancelTotal + data[i].grandTotal
        } else {
          count = count + 1
          countstockIntotal = countstockIntotal + +data[i].grandTotal
        }
      }
      let text = util.convertCurrency(counttotal)
      let txtgrandTotal = util.ThaiBaht(text)
      detail = {
        totalItems: data.length,
        counttotal: util.convertCurrency(counttotal),
        conntCancelItems: conntCancelItems,
        count: count,
        startAt: moment(String(this.date.start)).format('DD MMMM YYYY'),
        endAt: moment(String(this.date.end)).format('DD MMMM YYYY'),
        items: this.itemsExport,
        shop: shops,
        countstockIntotal: util.convertCurrency(countstockIntotal),
        countCancelTotal: util.convertCurrency(countCancelTotal),
        txtgrandTotal: txtgrandTotal,
      }
      return detail
    },
  },
  created() {
    this.getStockOut()
  },
  methods: {
    ...util,
    updateItems() {
      let data = this.data || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const customer = item.customer || {}
        const customerName = customer.name || '-'
        const grandTotal = util.convertCurrency(item.grandTotal || 0)
        const billNo = item.billNo || '-'
        const stockNo = item.stockNo || '-'

        // Parse deleted at document.
        let classes = 'text-dark'
        let status = this.$i18n.t('active')
        if (item.deleted_at != null) {
          status = this.$i18n.t('cancel')
          classes = 'text-danger'
        }

        // Updated at
        let updatedAt = ''
        if (item.updated_at != undefined) {
          updatedAt = moment(String(item.updated_at)).format('DD/MM/YYYY HH:mm:ss')
        }

        const updatedBy = item.updated_by || {}
        const updatedByName = updatedBy.username || '-'

        // date of bill 
        let date = ''
        if (item.date != undefined) {
          date = moment(String(item.date)).format('DD/MM/YYYY')
        }

        // Created at
        let createdAt = ''
        if (item.created_at != undefined) {
          createdAt = moment(String(item.created_at)).format('DD/MM/YYYY HH:mm:ss')
        }
        detail.push({
          objectId: item.objectId,
          createdAt: createdAt,
          date: date,
          stockNo: stockNo,
          updatedAt: updatedAt,
          billNo: billNo,
          grandTotal: grandTotal,
          username: updatedByName,
          customer: customerName,
          _classes: classes,
          status: status,
        })
      }
      return detail
    },
    itemsExport() {
      let data = this.dataExport
      let detail = []
      let customer = ''
      let classes = ''
      let status = ''

      if (data != undefined) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].customer != undefined) {
            customer = data[i].customer.name
          } else {
            customer = '-'
          }
          if (data[i].deleted_at != null) {
            classes = 'text-danger'
            status = 'ยกเลิก'
          } else {
            classes = 'text-dark'
            status = 'ใช้งาน'
          }

          let updated_by = ''
          if (data[i].updated_by !== undefined) {
            if (data[i].updated_by.username !== undefined) {
              updated_by = data[i].updated_by.username
            }
          }

          detail.push({
            objectId: data[i].objectId,
            date: moment(String(data[i].created_at)).format(
              'DD/MM/YYYY HH:mm:ss'
            ),
            stockNo: data[i].stockNo,
            billNo: data[i].billNo,
            grandTotal: util.convertCurrency(data[i].grandTotal),
            username: updated_by,
            customer: customer,
            _classes: classes,
            status: status,
          })
        }
      } else {
        detail = []
      }
      return detail
    },
    setSearch(searchAttr, keyword) {
      this.keyword = keyword
      this.searchAttr = searchAttr
      this.getStockOut()
    },
    stockDocumentDetail(item, index, event) {
      this.$router.push('/inventory/stock-out/' + item.objectId)
    },
    getDataExport() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let limit = this.meta_data.last_page * this.meta_data.limit || 50
      if (limit === 0) {
        limit = 50
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: 1,
        limit: limit,
        searchAttr: this.searchAttr,
        searchVal: this.keyword,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/getstockout',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.dataExport = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getStockOut(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
        searchAttr: this.searchAttr,
        searchVal: this.keyword,
      }
      this.loading = true
      const headers = { shopObjectId: shopObjectId }

      axios({
        url: '/api/v1.0/' + uid + '/getstockout',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.documents
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
          this.getDataExport()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteStockDocument(stockDocumentObjectId) {
      const uid = this.uid
      const plan = this.users.currentPlan
      const headers = { shopObjectId: this.shopObjectId }

      axios({
        method: 'post',
        url:
          '/api/v1.0/' + uid + '/deletestockdocument/' + stockDocumentObjectId,
        params: { plan: plan },
        headers: headers,
      })
        .then(() => {
          this.popupModal = false
          this.getStockOut()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    exportReport() {
      const setdata = this.setExportdata
      const uid = `${localStorage.getItem('shopsUid')}`
      let html = exportStock.exportStockOutList(setdata)
      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      const headers = { shopObjectId: this.shopObjectId }
      const startAt = moment(String(this.date.start)).format('YYYY_MM_DD')
      const endAt = moment(String(this.date.end)).format('YYYY_MM_DD')
      let name =
        'รายการจ่ายออกสินค้า_วันที่_' + startAt + '_ถึง_' + endAt + '.pdf'
      report({
        method: 'post',
        url: '/print/v1.0/' + uid + '/htmltopdf/a4',
        data: data,
        headers: headers,
        responseType: 'blob', // arraybuffer
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style src="../../../assets/styles/inventory.css" scoped></style>

<style scoped>
.table {
   background: #4B5F71 !important ;
}
.status-header {
  color: #4B5F71 !important; /* Set text color for status header */
}

.ctable-stockout tbody tr td .text-dark {
  color: inherit !important; /* Inherit color from parent */
}
</style>