<template>
  <div class="mt-3">
    <CRow>
      <CCol class="col-4 col-md-2 col-xl-2">
        <select v-model="searchAttr" class="custom-select">
          <option value="stockNo">{{ $t('documentNo') }}</option>
          <option value="billNo">{{ $t('billNo') }}</option>
          <option value="customer">{{ $t('customer') }}</option>
        </select>
      </CCol>
      <CCol class="col-6 col-md-8 col-xl-8">
        <input
          v-model="keyword"
          type="text"
          class="form-control"
          :placeholder="$t('keyword')"
        />
      </CCol>
      <CCol class="col-2 col-md-2 col-xl-2">
        <CButton color="success" block @click="getSearch(searchAttr, keyword)">
          <span>  {{ $t('search') }} </span>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchAttr: 'stockNo',
      keyword: '',
    }
  },
  methods: {
    getSearch(searchAttr, keyword) {
      this.searchAttr = searchAttr
      this.keyword = keyword
      this.$emit('getSearch', searchAttr, keyword)
    },
  },
}
</script>
